@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  box-sizing: border-box !important;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

@layer utilities {
  .center {
    @apply flex justify-center items-center
  }
  .v-center {
    @apply flex items-center
  }
  .extlink {
    @apply text-primary cursor-pointer font-bold
  }
}

.inc{
  animation: increase 1.5s infinite;
}

@keyframes increase {
  0% {
    left:0%;
    right:100%;
    width:0%;
  }
  20% {
    left:0%;
    right:60%;
    width:40%;
  }
  70% {
    right:0%;
    left:60%;
    width:40%;
  }
  100% {
    left:100%;
    right:0%;
    width:0%;
  }
}
